import React from 'react'
import styles from './MainPage.module.scss'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import block1 from '../../imgs/block1.svg'
import block2 from '../../imgs/block2.svg'
import block3 from '../../imgs/block3.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import Carousel from '../../components/Carousel'
import CarouselMobile from '../../components/CarouselMobile'
export default function MainPage() {
    const { t } = useTranslation()
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
    const contactVariants = {
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0 }
    }
    const contactVariantsMinus = {
        initial: { opacity: 0, y: -80 },
        animate: { opacity: 1, y: 0 }
    }
    const contactVariantsX = {
        initial: { opacity: 0, x: 125 },
        animate: { opacity: 1, x: 0 }
    }
    const contactVariantsMinusX = {
        initial: { opacity: 0, x: -125 },
        animate: { opacity: 1, x: 0 }
    }


    const slides = [
        {
            id: 1,
            name: t('slide1Name'),
            text: t('slide1Text')
        },
        {
            id: 2,
            name: t('slide2Name'),
            text: t('slide2Text')
        },
        {
            id: 3,
            name: t('slide3Name'),
            text: t('slide3Text')
        },
        {
            id: 4,
            name: t('slide4Name'),
            text: t('slide4Text')
        },
        {
            id: 5,
            name: t('slide5Name'),
            text: t('slide5Text')
        },
        {
            id: 6,
            name: t('slide6Name'),
            text: t('slide6Text')
        },
        {
            id: 7,
            name: t('slide7Name'),
            text: t('slide7Text')
        },
        {
            id: 8,
            name: t('slide8Name'),
            text: t('slide8Text')
        },
        {
            id: 9,
            name: t('slide9Name'),
            text: t('slide9Text')
        },
        {
            id: 10,
            name: t('slide10Name'),
            text: t('slide10Text')
        },
        {
            id: 11,
            name: t('slide11Name'),
            text: t('slide11Text')
        },
        {
            id: 12,
            name: t('slide12Name'),
            text: t('slide12Text')
        },
        {
            id: 13,
            name: t('slide13Name'),
            text: t('slide13Text')
        },
        {
            id: 14,
            name: t('slide14Name'),
            text: t('slide14Text')
        },
    ];

    return (
        <div className={styles.MainPage}>
            <motion.div
                initial={'initial'}
                whileInView={'animate'}
                transition={{ duration: 0.7 }}
                variants={contactVariants} className={styles.header}>

                <motion.a href='/login' className={styles.Terminal} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                    {t('Unlock Your Assets Terminal')}
                    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none">
                        <path d="M4.5 12L12.5 4M12.5 4H6.5M12.5 4V10" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </motion.a>
                <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                    {t('Your Path to Financial Success')}
                </motion.h1>
                <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.6 }}>
                    {t('Preparation first, then action. This is how you make the best trades')}
                </motion.p>
                <motion.a href='/login' className={styles.Cabinet} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.9 }}>
                    {t('join_us')}
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} viewBox="0 0 16 17" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.6905 14.1904C14.6668 13.214 14.6668 11.6427 14.6668 8.5C14.6668 5.3573 14.6668 3.78595 13.6905 2.80964C12.7142 1.83333 11.1429 1.83333 8.00016 1.83333C4.85747 1.83333 3.28612 1.83333 2.30981 2.80964C1.3335 3.78595 1.3335 5.3573 1.3335 8.5C1.3335 11.6427 1.3335 13.214 2.30981 14.1904C3.28612 15.1667 4.85747 15.1667 8.00016 15.1667C11.1429 15.1667 12.7142 15.1667 13.6905 14.1904ZM9.88578 6.11438C10.1619 6.11438 10.3858 6.33824 10.3858 6.61438L10.3858 9.44281C10.3858 9.71895 10.1619 9.94281 9.88578 9.94281C9.60964 9.94281 9.38578 9.71895 9.38578 9.4428L9.38578 7.82149L6.4681 10.7392C6.27284 10.9344 5.95625 10.9344 5.76099 10.7392C5.56573 10.5439 5.56573 10.2273 5.76099 10.0321L8.67867 7.11438H7.05735C6.78121 7.11438 6.55735 6.89052 6.55735 6.61438C6.55735 6.33823 6.78121 6.11438 7.05735 6.11438L9.88578 6.11438Z" fill="#FCFCFC" />
                    </svg>
                </motion.a>
            </motion.div>
            <div className={styles.section1}>
                <div className={styles.section1Body}>
                    <motion.div className={styles.top_block} variants={contactVariantsMinus} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 0.3 }}>
                        <div>{t('How to work ?')}</div>
                        <h1>{t('Trade with a professional')}</h1>
                        {/* <p>{t('Preparation first, then action. This is how you make the best trades')}</p> */}
                    </motion.div>
                    <div className={styles.bottom_block}>
                        <motion.div className={styles.block} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                            <div className={styles.top}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                                    <path d="M29.3332 14V16C29.3332 22.2854 29.3332 25.4281 27.3805 27.3807C25.4279 29.3334 22.2852 29.3334 15.9998 29.3334C9.71444 29.3334 6.57175 29.3334 4.61913 27.3807C2.6665 25.4281 2.6665 22.2854 2.6665 16C2.6665 9.71463 2.6665 6.57193 4.61913 4.61931C6.57175 2.66669 9.71444 2.66669 15.9998 2.66669H17.9998" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <circle cx="25.3335" cy="6.66669" r={4} stroke="#FCFCFC" strokeWidth="1.5" />
                                    <path d="M9.3335 18.6666L12.3907 15.6095C12.9114 15.0888 13.7556 15.0888 14.2763 15.6095L16.3907 17.7238C16.9114 18.2445 17.7556 18.2445 18.2763 17.7238L22.6668 13.3333M22.6668 13.3333V16.6666M22.6668 13.3333H19.3335" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {t('Profitability')}
                            </div>
                            <p className={styles.text}>
                                {t("Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading and a wide range of payment systems helps to perform financial operations quickly and comfortably.")}
                            </p>
                        </motion.div>
                        <motion.div className={styles.block} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.6 }}>
                            <div className={styles.top}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={33} height={32} viewBox="0 0 33 32" fill="none">
                                    <path d="M9.66699 13.3333C9.66699 11.4477 9.66699 10.5049 10.2528 9.9191C10.8386 9.33331 11.7814 9.33331 13.667 9.33331H19.0003C20.8859 9.33331 21.8288 9.33331 22.4145 9.9191C23.0003 10.5049 23.0003 11.4477 23.0003 13.3333V18.6666C23.0003 20.5523 23.0003 21.4951 22.4145 22.0809C21.8288 22.6666 20.8859 22.6666 19.0003 22.6666H13.667C11.7814 22.6666 10.8386 22.6666 10.2528 22.0809C9.66699 21.4951 9.66699 20.5523 9.66699 18.6666V13.3333Z" stroke="#FCFCFC" strokeWidth="1.5" />
                                    <path d="M16.9048 13.3333L15 16H17.6667L15.7619 18.6666" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.66699 16C5.66699 10.9717 5.66699 8.45751 7.22909 6.89541C8.79119 5.33331 11.3053 5.33331 16.3337 5.33331C21.362 5.33331 23.8761 5.33331 25.4382 6.89541C27.0003 8.45751 27.0003 10.9717 27.0003 16C27.0003 21.0283 27.0003 23.5425 25.4382 25.1045C23.8761 26.6666 21.362 26.6666 16.3337 26.6666C11.3053 26.6666 8.79119 26.6666 7.22909 25.1045C5.66699 23.5425 5.66699 21.0283 5.66699 16Z" stroke="#FCFCFC" strokeWidth="1.5" />
                                    <path d="M5.66699 16H3.00033" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M29.667 16H27.0003" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M5.66699 12H3.00033" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M29.667 12H27.0003" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M5.66699 20H3.00033" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M29.667 20H27.0003" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M16.3335 26.6667L16.3335 29.3334" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M16.3335 2.66669L16.3335 5.33335" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M12.3335 26.6667L12.3335 29.3334" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M12.3335 2.66669L12.3335 5.33335" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M20.3335 26.6667L20.3335 29.3334" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M20.3335 2.66669L20.3335 5.33335" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                </svg>
                                {t('Technology')}
                            </div>
                            <p className={styles.text}>
                                {t("The company's modern equipment helps traders to trade at the high level accepted in the Forex industry.")}
                                <br/>
                                <br/>
                                {t("Reliable trading platforms, their mobile versions and fast order execution speed increase profitable opportunities.")}
                            </p>
                        </motion.div>
                        <motion.div className={styles.block} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1.2, delay: 0.9 }}>
                            <div className={styles.top}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={33} height={32} viewBox="0 0 33 32" fill="none">
                                <path d="M4.6665 12C4.6665 8.22876 4.6665 6.34315 5.83808 5.17157C7.00965 4 8.89527 4 12.6665 4H20.6665C24.4377 4 26.3234 4 27.4949 5.17157C28.6665 6.34315 28.6665 8.22876 28.6665 12V18.6667C28.6665 21.1808 28.6665 22.4379 27.8855 23.219C27.1044 24 25.8473 24 23.3332 24H9.99984C7.48568 24 6.2286 24 5.44755 23.219C4.6665 22.4379 4.6665 21.1808 4.6665 18.6667V12Z" stroke="#FCFCFC" strokeWidth="1.5" />
                                <path d="M29.9997 28H3.33301" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M20.6665 20H12.6665" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                                </svg>
                                {t('Services')}
                            </div>
                            <p className={styles.text}>
                                {t("Since 2014, Revolvo has been providing the best services on the market. Our services include analytical materials: modern trading platforms, personal support, training and a wide range of profitable trading assets.")}
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
            <div className={styles.section2}>
                <div className={styles.section2Body}>
                    <motion.div className={styles.top_block} variants={contactVariantsMinus} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 0.3 }}>
                        <div>{t('How to work ?')}</div>
                        <h1>{t('Advantages of online platform')}</h1>
                        {/* <p>{t('Preparation first, then action. This is how you make the best trades')}</p> */}
                    </motion.div>
                    <div className={styles.blocks}>
                        <motion.div className={styles.block} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                            <img loading="lazy" src={block1} alt="" />
                            <div className={styles.block_content}>
                                <span>{t('Explore Our Instrument Library')}</span>
                                <h3>{t('Wide range of instruments')}</h3>
                                <p>
                                    {t('Over 170 high-yielding assets, including the most popular stocks and indices, precious metals, energy and cryptocurrencies.')}
                                    <br/>
                                    <br/>
                                    {t('Conveniently use your favourite tools to analyse and manage your investments. Our advanced technology ensures fast access to the markets and reliable protection of your funds.')}
                                </p>                                
                            </div>
                        </motion.div>
                        <motion.div className={styles.block} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.6 }}>
                            <div className={styles.block_content}>
                                    <span>{t('RiskGuard')}</span>
                                    <h3>{t('Timely risk management')}</h3>
                                    <p>
                                        {t('Capture profits and limit potential losses in time by setting Stop Loss and Take Profit right on the chart.')}
                                        <br/>
                                        <br/>

                                        {t('Our advanced analytical tools allow you to make informed trading decisions based on up-to-date information.')}
                                        <br/>
                                        <br/>

                                        {t('Charting functions and order management tools ensure effective control of your positions.')}
                                    </p>
                            </div>
                            <img loading="lazy" src={block2} alt="" />
                        </motion.div>
                        <motion.div className={styles.block} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.9 }}>
                            <img loading="lazy" src={block3} alt="" />
                            <div className={styles.block_content}>
                                <span>{t('How to work ?')}</span>
                                <h3>{t("All operations are supervised")}</h3>
                                <p>
                                    {t('The ability to manage open and closed trades, work with limit orders, as well as view the history of all trades for each selected instrument, allows you to fully control the trading process and form an individual profitable strategy.')}
                                </p>                                
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>

            <div className={styles.section3}>
                <div className={styles.section3Body}>
                    <div className={styles.how}>{t('How to work ?')}</div>
                    <div className={styles.block_content}>
                        <motion.div className={styles.left_side} variants={contactVariantsMinusX} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 0.3 }}>
                            <h1>{t('Advantages of' + ' ' +
                            'online platform')}</h1>
                            {/* <p>{t('Preparation first, then action. This is how you make the best trades')}</p> */}
                        </motion.div>
                        <motion.div className={styles.right_side} variants={contactVariantsX} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 0.3 }}>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                                 sx={{backgroundColor: expanded === 'panel1'?'#1D1D20 !important':'#18181B !important',borderRadius:'32px !important', '& .MuiAccordionSummary-content':{margin:0}}}>
                                <AccordionSummary
                                sx={{padding:'21px 29px 21px 21px', margin:"0 !important"}}
                                expandIcon={<ExpandMoreIcon sx={{color:'#FFF'}} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <div className={styles.accordion_head}>
                                    <span>01</span>
                                    <h2>{t("Best Trading Solutions")}</h2>
                                </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{padding:'21px 29px 21px 21px'}}>
                                <p className={styles.accordion_text}>
                                    {t("The Autochartist technical advisor built into the platform opens up even more profitable opportunities.")}
                                    <br />
                                    <br />
                                    {t("Effective methods of technical analysis help to find the right market entry points and make the right trading decisions.")}
                                </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                                 sx={{backgroundColor: expanded === 'panel2'?'#1D1D20 !important':'#18181B !important',borderRadius:'32px !important', '& .MuiAccordionSummary-content':{margin:0}}}>
                                <AccordionSummary
                                sx={{padding:'21px 29px 21px 21px', margin:"0 !important"}}
                                expandIcon={<ExpandMoreIcon sx={{color:'#FFF'}} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <div className={styles.accordion_head}>
                                    <span>02</span>
                                    <h2>{t("Always up to date")}</h2>
                                </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{padding:'21px 29px 21px 21px'}}>
                                <p className={styles.accordion_text}>
                                    {t("Revolvo is the first trader portal integrated into an online platform that allows you to track important financial news without being distracted from trading.")}
                                    <br />
                                    <br />
                                    {t("Use data on political and economic events, as well as expert forecasts, to increase your capital.")}
                                </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
                                 sx={{backgroundColor: expanded === 'panel3'?'#1D1D20 !important':'#18181B !important',borderRadius:'32px !important', '& .MuiAccordionSummary-content':{margin:0}}}>
                                <AccordionSummary
                                sx={{padding:'21px 29px 21px 21px', margin:"0 !important"}}
                                expandIcon={<ExpandMoreIcon sx={{color:'#FFF'}} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <div className={styles.accordion_head}>
                                    <span>03</span>
                                    <h2>{t("Trading mode 1 click")}</h2>
                                </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{padding:'21px 29px 21px 21px'}}>
                                <p className={styles.accordion_text}>
                                    {t("One-Click Trading provides the ability to open and close trades with just one click of the mouse, simplifying the trading process and saving time on operations.")}
                                    <br />
                                    <br />
                                    {t("This feature is ideal for traders who value the speed of opening trades.")}
                                </p>
                                </AccordionDetails>
                            </Accordion>
                        </motion.div>
                    </div>
                </div>
            </div>
            <motion.div className={styles.section4} variants={contactVariants} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 0.3 }}>
                <div className={styles.section4Body}>
                <div className={styles.left_side}>
                    <h1>{t('Real Experiences' + ' ' +
                    'With Trading')}</h1>
                </div>
                <Carousel slides={slides}/>
                <CarouselMobile slides={slides}/>
                </div>
            </motion.div>
            <div className={styles.section5} >
                <div className={styles.section5Body}>
                    <div className={styles.top}>
                        <div className={styles.why}>{t("Why us?")}</div>
                        <h1>Revolvo:<br/>
                            {t('Always on the side of the trader')}
                        </h1>
                        {/* <p>{t('Preparation first, then action. This is how you make the best trades')}</p> */}
                    </div>
                    <div className={styles.bottom}>
                        <motion.div className={styles.left} variants={contactVariantsMinusX} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 0.3 }}>
                            <div className={styles.why}>{t("Why us?")}</div>
                            <h1>Revolvo:<br/>
                            {t('Always on the side of the trader')}
                            </h1>
                        </motion.div>
                        <div className={styles.right}>
                            <motion.div className={styles.block_content} variants={contactVariantsX} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 0.3 }}>
                                <h1>10</h1>
                                <p>{t('Years')}</p>
                            </motion.div>
                            <motion.div className={styles.block_content} variants={contactVariantsX} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 1.2 }}>
                                <h1>4</h1>
                                <p>{t('Platforms')}</p>
                            </motion.div>
                            <motion.div className={styles.block_content} variants={contactVariantsX} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 2.1 }}>
                                <h1>149</h1>
                                <p>{t('Instruments')}</p>
                            </motion.div>
                            <motion.div className={styles.block_content} variants={contactVariantsX} initial={'initial'} whileInView={'animate'} transition={{ duration: 0.9, delay: 3 }}>
                                <h1>30</h1>
                                <p>{t('Signals')}</p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
